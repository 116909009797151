<template>
  <!-- 电子围栏页面 -->
  <div>
    <!-- 头部开始 -->
    <el-row class="pur-top">
      <el-col :span="3">
        <span class="pur-size">{{ purTitle }}</span></el-col>
        <el-col :span="21" class="pur-right">
            <el-button size="small" @click="openPur">删除</el-button>
            <el-button size="small" style="margin-left: 24px" @click="addPurs" type="primary">新增</el-button>
            <el-input
            @keyup.enter.native="search"
            size="small"
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            v-model="purSearch">
            </el-input>
        </el-col>
    </el-row>
    <!-- 头部结束 -->
    <!-- 内容开始 -->
    <div class="pur-nav"
    v-loading="loading"
    element-loading-text = "加载中...">
      <!-- 表格 -->
      <div class="pur-table">
        <el-table
          height="100%"
          border
          :data="tableData"
          :header-cell-style="{ background: '#F6F6F6', color: '#333' }"
          style="width: 100%;"
          @selection-change="purChange">
            <el-table-column type="selection"></el-table-column>
            <el-table-column prop="id" v-if="show">
            </el-table-column>
            <el-table-column label="围栏类型" :show-overflow-tooltip="true" width="90">
              <template slot-scope="scope"> 
                <span v-if="scope.row.fenceType == 1">圆形围栏</span>
                <span v-if="scope.row.fenceType == 2">多边形围栏</span>
                <span v-if="scope.row.fenceType == 3">道路围栏</span>
                <span v-if="scope.row.fenceType == 4">行政区围栏</span>
              </template>
            </el-table-column>
            <el-table-column label="路线名称" :show-overflow-tooltip="true" prop="routeName" width="160">
            </el-table-column>
            <el-table-column label="起点名称" :show-overflow-tooltip="true" prop="startPoint">
              <template slot-scope="scope">
                <span v-if="scope.row.startPoint">{{scope.row.startPoint}}</span>
                <span v-if="scope.row.startPointCoordinate">{{scope.row.startPointCoordinate}}</span>
              </template>
            </el-table-column>
            <el-table-column label="终点名称" :show-overflow-tooltip="true" prop="endPoint">
              <template slot-scope="scope">
                <span v-if="scope.row.endPoint">{{scope.row.endPoint}}</span>
                <span v-if="scope.row.endPointCoordinate">{{scope.row.endPointCoordinate}}</span>
              </template>
            </el-table-column>
            <el-table-column label="时间设定" :show-overflow-tooltip="true" width="180">
              <template slot-scope="scope">
                <span>{{scope.row.alarmStartTime}} -</span><span> {{scope.row.alarmEndTime}}</span>
              </template>
            </el-table-column>
            <el-table-column label="车程预估时间" :show-overflow-tooltip="true" width="110">
              <template slot-scope="scope">
                <span>{{scope.row.estimatedTime}}分钟</span>
              </template>
            </el-table-column>
            <el-table-column label="预估时间偏差" :show-overflow-tooltip="true" width="110">
              <template slot-scope="scope">
                <span>{{scope.row.estimatedTimeDeviation}}分钟</span>
              </template>
            </el-table-column>
            <el-table-column label="围栏相关车辆" :show-overflow-tooltip="true" width="110">
              <template slot-scope="scope">
                <div v-if="scope.row.bindCars > 0">
                  <span style="color:rgba(58, 111, 195, 1);cursor: pointer;" @click="details(scope.row)">详情</span>
                </div>
                <span v-else>---</span>
              </template>
            </el-table-column>
            <el-table-column label="是否为运煤路线" :show-overflow-tooltip="true" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.coalTransRoute">是</span>
                <span v-else>否</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="允许停留时长" :show-overflow-tooltip="true" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.allowStopTime">{{scope.row.allowStopTime}}分钟</span>
                <span v-else>---</span>
              </template>
            </el-table-column> -->
            <el-table-column label="偏移报警距离" :show-overflow-tooltip="true" width="110">
              <template slot-scope="scope">
                <span>{{scope.row.warningDistance}}米</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="查看"
              width="100" fixed="right">
              <template slot-scope="scope">
                <span @click="EditPurs(scope.row)">报警路线</span>
              </template>
            </el-table-column> -->
            <el-table-column
              label="操作" fixed="right" width="180">
              <template slot-scope="scope">
                <el-button @click="EditPurs(scope.row)" size="mini">编辑</el-button>
                <el-button @click="Unbind(scope.row)" size="mini" v-if="scope.row.bindCars != 0">取消绑定</el-button>
                <el-button @click="bind(scope.row)" size="mini" v-else>绑定车辆</el-button>
              </template>
            </el-table-column>
          <!-- 暂无数据 -->
          <div slot="empty" class="dataAvailable">
              <div class="dataAva">
                <img src="../assets/zanwushuju.png" alt="">
                <p>暂无数据，您可以新建试试</p>
                <el-button size="small" style="margin-left: 24px" @click="addPurs">新增</el-button>
              </div>
          </div>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pur-pages">
        <el-pagination
        background
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size.sync="size"
        :pager-count="5"
        :current-page.sync="current"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @current-change='getpurcon'
        @size-change='getpurcon'>
        </el-pagination>
      </div>
      <!-- 抽屉 查看车辆详情功能 -->
      <el-drawer title="车辆详情列表" :visible.sync="drawer" size="500px" @close="closedrawerno">
        <div class="detilsBox" v-if="delistData.length != 0">
          <h5>路线名称 {{title}}</h5>
          <div  class="detilconent" v-for="(v,k) in delistData" :key="k">
            <span style="width: 25%;">姓名：{{v.driverName}}</span>
            <span>电话：{{v.phone}}</span>
            <span>车牌号：{{v.licensePlate}}</span>
          </div>
        </div>
        <div class="detilsBox" v-else style="margin-top:40%;">
          <el-empty description="暂无车辆信息"></el-empty>
        </div>
      </el-drawer>
      <!-- 车辆绑定弹窗 -->
      <el-dialog
        title="绑定车辆"
        :closeOnClickModal="false"
        :visible.sync="bindShow"
        width="35%"
        :before-close="bindClose">
          <el-form style="width:95%;" :model="bindData" :rules="bindrules" ref="bindData" size="small" label-width="100px">
            <el-form-item label="车辆绑定">
              <el-select multiple v-model="bindData.newcarId" clearable filterable placeholder="请选择车辆信息" style="width:100%;">
                  <el-option
                  v-for="item in bindoptions"
                  :key="item.id"
                  :label="item.licensePlate + ' ' + item.driverName + ' ' + item.phone"
                  :value="item.id + ',' + item.licensePlate">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="bindsubmitm('bindData')" style="float:right;margin:5% 0 0 2%;">确定</el-button>
              <el-button @click="bindresetForm" style="float:right;margin:5% 0 0 2%;">取消</el-button>
            </el-form-item>
          </el-form>
      </el-dialog>
    </div>
    <!-- 内容结束 -->
  </div>
</template>
<script>
export default {
    data() {
        return {
          loading:false,
          current:1,              // 当前页数
          size:30,                // 显示条数
          total:0,                // 总条数
          delmul:[],              // 批量删除
          fenIds:[],
          show:false,
          purTitle: "",           // 标题
          purSearch: "",          // 搜索        
          multipleSelection: [],  // 选中数据
          tableData: [],          // 表格数据  
          // 车辆详情
          drawer:false,
          title:'',
          delistData:[],
          // 车辆绑定
          bindShow:false,
          bindData:{
            newcarId:[],    // 选中车辆列表
          },
          bindrules: {},
          bindoptions: [],
        };
    },
    created() {},
    mounted(){
      this.purTitle = this.$route.meta.title;
      this.getpurcon();
    },
    methods: {
      // 车辆详情
      details(data){
        this.drawer = true;
        this.title = data.routeName;
        this.api.riskElect.listCars({fenceId:data.id})
        .then(res=>{
          if(res.data.code == 200){
            this.delistData = res.data.data;
          }
        })
      },
      // 关闭抽屉回调
      closedrawerno(){
        this.drawer = false;
      },
      // 表格数据 
      getpurcon(){
        this.loading = true;
        // 传参
        var listByPageData = {
          searchStr:this.purSearch,
          page:this.current,
          size:this.size
        }
        // 渲染表格
        this.api.riskElect.all(listByPageData)
        .then(res=>{
          this.loading = false;
          if(res.data.code == 200){
              this.tableData = res.data.data.dataList;
              this.total = res.data.data.totalCount;
          
          }
        })
      },
      // 搜索功能
      search() {
        this.tableData = [];
        this.getpurcon();
        this.current = 1;
      },
      // 表格选中数据
      purChange(val) {
        this.multipleSelection = val;
        this.delmul = [];
        this.fenIds = [];
        for(let i=0;i<this.multipleSelection.length;i++){
          this.delmul.push(this.multipleSelection[i].id) 
          this.fenIds.push(this.multipleSelection[i].startPointFenceid) 
          this.fenIds.push(this.multipleSelection[i].fenceId) 
          this.fenIds.push(this.multipleSelection[i].endPointFenceid) 
        }
      },
      // 新建电子围栏信息
      addPurs(){
          this.$router.push({name:"AddElectronicFence"})
      },
      // 编辑单子围栏信息
      EditPurs(row){
        this.api.riskElect.one({id:row.id})
        .then(res=>{
          if(res.data.code == 200){
            let purdata = res.data.data;
            console.log(res.data.data)
            purdata.id = row.id;
            purdata.lists = [];
            purdata.startcirclePath = {};
            purdata.endcirclePath = {};
            purdata.startlng = {};
            purdata.endlat = {};
            // purdata.excFence = [];
            sessionStorage.setItem('queryAll',JSON.stringify(purdata));
            this.$router.push({name:"EditElectronicFence"})
          }
        })
      },
      // 删除功能
      openPur() {
          if(this.multipleSelection == ""){
            this.$message({
                type: 'warning',
                message: '请选择要删除的数据!'
            });
          }else{
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
              
              this.api.riskElect.alldel({ids:this.delmul,fenIds:this.fenIds})
              .then(res=>{
                if(res.data.code == 200){
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                  this.tableData = [];
                  this.getpurcon();
                  this.current = 1;
                }
              })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
          }
      },

      // 车辆解绑
      Unbind(data){
          this.$confirm('此操作将解除绑定车辆, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.api.riskCar.unBindFence({fenceId:data.fenceId})
            .then(res=>{
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '解绑车俩成功!'
                });
                this.tableData = [];
                this.getpurcon();
                this.current = 1;
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消解绑车辆操作'
            });          
          });
      },
      // 车辆绑定
      bind(data){
        this.bindShow = true;
        this.bindData.fenceId = data.fenceId;
        // isBindCar:true,{fid:data.fenceId}
        this.api.riskCar.allData()
        .then(res=>{
          if(res.data.code == 200){
            this.bindoptions = res.data.data;
          }
        })
      },
      // 绑定车辆弹窗取消
      bindClose(){
        this.bindShow = false;
        this.bindData = {}
      },
      // 取消绑定车辆信息提交
      bindresetForm() {
        this.bindShow = false;
        this.bindData = {}
      },
      // 提交绑定数据
      bindsubmitm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let newarr = [];
            let arrs;
            for(let key in this.bindData.newcarId){
              arrs = this.bindData.newcarId[key].split(',');
              newarr.push({id:arrs[0],licensePlate:arrs[1],fenceId:this.bindData.fenceId})
            }
            this.api.riskCar.updateBatch(newarr)
            .then(res=>{
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '绑定车辆成功!'
                });
                this.bindData = {};
                this.tableData = [];
                this.getpurcon();
                this.current = 1;
                this.bindShow = false;
              }
            })
          }
        });
      },

    },
};
</script>
<style lang="less" scoped>
// 详情样式开始
.detilsBox{
  width: 100%;
  height: 100%;
  padding: 25px;
  box-sizing: border-box;
  h5{
    font-size: 16px;
    line-height: 58px;
    color: #333333;
  }
  .detilconent{
    width: 100%;
    span{
      display: inline-block;
      width: 33.33%;
      font-size: 14px;
      line-height: 24px;
      color: #333333;
    }
  }
}
// 详情样式结束
// 头部开始
.pur-top {
  width: 100%;
  height: 50px;
  padding: 9px 24px 9px 32px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  border: 1px solid rgba(102, 102, 102, 0.15);
  .pur-size {
    font-size: 16px;
    line-height: 32px;
    color: #333;
    font-weight: 600;
  }
  .pur-right {
    .el-input {
      width: 200px;
      float: right;
    }
    .el-button {
      margin-left: 16px;
      float: right;
    }
  }
}
// 头部结束
// 内容开始
.pur-nav {
  width: calc(100% - 32px);
  height: calc(100vh - 142px);
  background-color: #fff;
  margin: 8px;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  padding: 8px;
  .pur-table{
    width:100%;
    height: 94%;
  } 
  // 暂无数据样式开始
  .dataAvailable{
    width: 100%;
    height: 245px;
    position: relative;
    margin-top:185px;
    margin-bottom:279px;
    .dataAva{
      position: absolute;
      top:75%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      img{
        width: 371px;
        height: 200px;
        margin: auto;
      }
      p{
        font-size: 16px;
        line-height: 24px;
        color: #333;
      }
      .el-button{
        margin-bottom:100px;
      }
    }
  }
  // 暂无数据样式结束
  .pur-pages {
    width: 100%;
    height: 6%;
    padding: 6px 0 0 12px;
    box-sizing:border-box;
    .el-pagination {
      float: right;
    }
  }
}
// 内容结束
</style>